<div class="cta-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-9 col-md-12">
        <span>Relish the taste of India.</span>
        <h2>Come & Experience Our Best of World Class Cuisine</h2>
      </div>
      <div class="col-lg-3 col-md-12 text-right">
        <a href="/#contact" class="btn btn-primary">Contact Us</a>
      </div>
    </div>
  </div>
</div>
