<app-home-banner></app-home-banner>

<app-about></app-about>

<!-- <app-offer></app-offer> -->

<app-stunning-things></app-stunning-things>

<!-- <app-video></app-video> -->

<app-feedback></app-feedback>

<app-restaurant-menu></app-restaurant-menu>

<!-- <app-features></app-features> -->

<app-call-to-action></app-call-to-action>

<!-- <app-chefs></app-chefs> -->

<!-- <app-gallery></app-gallery> -->

<!-- <app-partner></app-partner> -->

<app-contact></app-contact>

<!-- <app-instagram></app-instagram> -->
