import { baseConfig } from './base-config';
import { IConfig } from './config.interface';

/**
 * Client specific overrides
 */
export const config: IConfig = {
  ...baseConfig,
  //! All client specific override needs to be applied after here.

  /**
   * Enable this to use default shared api hosted at api.storefront.readyorder.com.au
   */
  apiSubDomainPrefix: null,

  appSubDomainPrefix: 'www',

  appDevSubDomainPrefix: 'storefront',

  repository: 'ReadyOrder_IndianTemptations',

  appName: 'IndianTemptations',

  domainName: 'indiantemptations.com.au',

  mapRootDomain: false,

  storefront: {
    contact: {
      email: [] as { label: string; value: string }[],
      phone: [
        { label: '08 8260 5757', value: '0882605757' },
        { label: '0481 874 499', value: '0481874499' },
        { label: '0401 874 499', value: '0401874499' },
      ],
      address: '490 Main North Road, Blair Athol SA 5084',
      googleLink: 'https://goo.gl/maps/etfUA5ax1deN9yHj9',
    },
    openingHours: {
      week: { startTime: '11:30AM', endTime: '2:00AM' },
      weekend: { startTime: '11:30AM', endTime: '2:00AM' },
    },
    restaurantId: 'z4qMxT6D5h1bzRj3o5',
    gloriaFoodCuid: '9b93d91d-416c-413d-a883-623f108ea472',
    gloriaFoodRuid: '9baae262-dfc1-4c03-bc27-d96d50799a8e',
    gloriaFoodUrl: 'https://pos.globalfoodsoft.com',
  },

  awsMailHostingProvider: {
    ...baseConfig.awsMailHostingProvider,
    emailSubject: 'New Website enquiry',
    emailToAddress: 'pcsharma@indiantemptations.com.au',
  },
};
