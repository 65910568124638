<div id="home" class="main-banner video-banner">
  <!-- FIXME: Disabling Video element until it is fully working -->
  <!-- <video
    autoplay="autoplay"
    muted
    loop="true"
    playsinline
    class="video-background"
  >
    <source src="assets/video/video.mp4" type="video/mp4" />
  </video> -->
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="main-banner-content">
          <h1>Indian Temptations</h1>
          <h2>The Real Test Of Indian Cuisine!</h2>
          <p class="text">
            Can you have Indian food for lunch, dinner and supper? You certainly
            can, with Indian Temptations! Situated along the stretch of Main
            North Road is this popular Indian restaurant, specialising in North
            Indian food.
          </p>
          <div class="button-group">
            <app-order-now-button></app-order-now-button>
            <!-- <app-table-reservation-button></app-table-reservation-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="down_arrow">
    <a href="#about" class="scroll_down" aria-label="Scroll Down"></a>
  </div>
</div>
