<div id="about" class="story-area ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="story-image">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about/about-img1.jpg" alt="image" />
              <img src="assets/img/about/about-img2.jpg" alt="image" />
            </div>
            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about/about-img3.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="story-content">
          <div class="section-title">
            <span class="text">About us</span>
            <h2>Welcome To Indian Temptations</h2>
          </div>
          <p>
            Indian Temptations is an Indian restaurant in Blair Athol. Located
            down Main North Road, it is easy to disregard the place (nothing
            fancy) at first or second glance, until you smell the aroma wafting
            from the restaurant and the people lining up for takeaway. Despite
            the modest size, the restaurant can serve a considerable number of
            customers with outside seating available as well.
          </p>
          <p>
            If its your first time at Indian Temptations, go for the perennial
            favourites such as Naan, Tandoori chicken, lamb Roganjosh and butter
            chicken. The spices and unique flavours will give you a kick. This
            diamond in the rough is open until four in the morning, seven days a
            week. So if you get the hunger pangs after a late shift or a night
            out, this is a good place to get a hot and filling meal.
          </p>
          <br />
          <p>Quality, you can trust</p>
          <div class="quote-info">
            <span> - The Indian Temptations Team</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="line-bg"><img src="assets/img/line.png" alt="line" /></div>
</div>
