import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  readonly testimonials = [
    {
      message: `We had a group dinner here last time. The service is friendly and fast. But the food is just OK.
        We had samosa chat as entree to share, and we had butter chicken, lamb korma, vegetable korma, and beef vindaloo. We shared the garlic nann and basmati rice. The samosa chat was yummy, probably the best dish from all the food we had. The rest of the dishes were all OK. I don't know if it was authentic, but I feel like most of the food we had was a bit bland and had not much flavours. A few of our nanns were burnt and I don't like to eat burnt food.        
        I had a mango lasi as well, it was nice but a bit too sweet for me. Overall it was a good experience, they have a big dining area and good atmosphere in the restaurant.`,
      userName: 'Jiexi Wu',
    },
    {
      message: `Recommended by good friends, this place is really good. Clean, flavours are so fresh, you can taste the fresh prepared spices. Had the goat curry and chicken tikka masala. The garlic naan was the best. Best Indian I've had.`,
      userName: 'Ben Fitzgerald',
    },
    {
      message: `Made a nice big order to share with my colleagues on a Friday. A lovely array of curries and super easy online ordering system. I ordered it at 11:40am and had lunch on the table by 12:30pm! staff were very friendly and I really enjoyed watching the little robot go around and serve food to the people dining in.
        The food itself was tasty, saucy, and the servings were actually generous in those little takeaway tubs! We actually had enough leftovers to feed a whole extra person after feeding about 12 of us already!
        Very impressed and thank you for being open at lunchtime. We'll be back for curry Fridays.`,
      userName: 'Chantelle TW',
    },
    {
      message: `Easily the best Indian restaurant in Adelaide. Really friendly owner, authentic curries and wide open dining space which is perfect for big groups/families. They even have a cool robot server!`,
      userName: 'Oli. Y',
    },
    {
      message: `We have come here many times previously and the food never let's us down. The service is quick and friendly and they have this cute robot which serves food. It's very clean and the food is delicous and in great quantities. We never are seem to finish the food.`,
      userName: 'Atharwa Khandelwal',
    },
  ];

  feedbackSlides: OwlOptions = {
    items: 1,
    margin: 0,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    mouseDrag: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='icofont-double-left'></i>",
      "<i class='icofont-double-right'></i>",
    ],
  };
}
